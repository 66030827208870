<template>
  <v-dialog v-model="dialog" width="680" @click:outside="cancel" :persistent="isLoading">
    <v-card class="pa-4">
      <v-card-title>
        <h3><strong>{{ title }}
          </strong></h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="isFormValid" ref="form">
            <v-row>
              <v-col cols="12">
                <label>
                  {{ $t('userRegisterDialog.label.loginId') }}
                  <v-tooltip right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" size="small">mdi-information</v-icon>
                    </template>
                    <span><small>{{ $t('userRegisterDialog.message.loginIdRule') }}</small></span>
                  </v-tooltip>
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="loginId" maxlength="19"
                  :disabled="this.dialogData.mode == 'upd'"
                  :rules="[rules.required, rules.maxLength(19)]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>
                  {{ $t('userRegisterDialog.label.name') }}
                  <v-tooltip right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" size="small">mdi-information</v-icon>
                    </template>
                    <span><small>{{ $t('userRegisterDialog.message.nameRule') }}</small></span>
                  </v-tooltip>
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="name" maxlength="50"
                  :rules="[rules.required, rules.maxLength(50)]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select density="compact" hide-details="auto" v-model="role" :items="roleOptions"
                  :label="$t('userRegisterDialog.label.role')" :rules="[rules.required]" :multiple="false"
                  item-value="id" item-title="label" :error-messages="errors" return-object></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="mb-0">
                  {{ $t('userRegisterDialog.label.password') }}
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="password" type="password"
                  :rules="[rules.password]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="mb-0">
                  {{ $t('userRegisterDialog.label.passwordConfirm') }}
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="passwordConfirm"
                  ref="passwordConfirm" type="password" :rules="[rules.samePass]"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="role && role.id != constant.ROLE.ADMIN.id">
              <v-col cols="12">
                <v-select v-if="role.id == constant.ROLE.ZONE_MANAGER.id" v-model="shopIds" :items="shopOptions"
                  density="compact" hide-details="auto" :label="$t('userRegisterDialog.label.shopId')" :multiple="true"
                  item-value="code" item-title="name" :error-messages="errors" return-object
                  :rules="[rules.shopIds]"></v-select>
                <v-select v-else v-model="shopId" :items="shopOptions" :label="$t('userRegisterDialog.label.shopId')"
                  :rules="[rules.shopId]" density="compact" hide-details="auto" :multiple="false" item-value="code"
                  item-title="name" :error-messages="errors" return-object></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <span class="ml-4" v-if="errorMessage" style="color: red"> {{ errorMessage }}</span>
        <div class="ml-4" v-for="data in errorList" :key="data.message" style="color: red">{{ data.message }}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> {{ $t('common.action.cancel') }} </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="!isFormValid">
          {{ this.dialogData.mode == 'upd' ? $t('userRegisterDialog.label.update') :
    $t('userRegisterDialog.label.register')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/apis/staff";
import utils from "@/utils";
import constant from "@/utils/constant"

export default {
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    // ロールプルダウンの内容
    roleOptions() {
      return utils.getRoleList(this.$t);
    },
    shopOptions() {
      return this.dialogData.shopList;
    },
    constant() {
      return constant;
    },
    title() {
      let title = this.$t('userRegisterDialog.label.user');
      let mode = this.dialogData.mode == 'upd'
        ? this.$t('userRegisterDialog.label.titleUpdate')
        : this.$t('userRegisterDialog.label.titleRegister');
      return title + mode;
    }
  },
  data() {
    return {
      rules: {
        required: v => {
          return this.hasLength(v) || this.$t('userRegisterDialog.validation.required');
        },
        maxLength: len => {
          return v => {
            if (!this.hasLength(v)) return true;
            if (v.length <= len) return true;
            return len + this.$t('userRegisterDialog.validation.max');
          }
        },
        minLength: len => {
          return v => {
            if (!this.hasLength(v)) return true;
            if (v.length >= len) return true;
            return len + this.$t('userRegisterDialog.validation.min');
          }
        },
        password: v => {
          this.$refs.passwordConfirm.validate();
          if (this.dialogData.mode == 'upd' && !this.hasLength(v)) return true;
          if (this.dialogData.mode == 'new' && !this.hasLength(v)) return this.$t('userRegisterDialog.validation.required');
          return true;
        },
        samePass: v => {
          if (this.dialogData.mode == 'new' && !this.hasLength(v)) this.$t('userRegisterDialog.validation.required');
          return v == this.password || this.$t('userRegisterDialog.validation.passwordConfirmationMismatch');
        },
        digit: v => {
          return RegExp(/^[0-9]*$/).test(v) || this.$t('userRegisterDialog.validation.digit');
        },
        shopIds: v => {
          if (this.role.id == constant.ROLE.ZONE_MANAGER.id && v.length < 2) {
            return this.$t('userRegisterDialog.validation.zoneManagerSiteCount');
          }
          return true;
        },
        shopId: v => {
          if (this.role.id == constant.ROLE.SITE_USER.id && !v) {
            return this.$t('userRegisterDialog.validation.siteUserSiteCount');
          }
          return true;
        },
      },
      dialog: true,
      isLoading: false,
      isFormValid: true,

      // 入力値
      loginId: "",
      name: "",
      role: "",
      password: "",
      passwordConfirm: "",
      shopId: "",
      shopIds: [],
      // エラー出力
      errorMessage: "",
      errorList: [],
    };
  },
  mounted() {
    if (!this.dialogData.user) return;
    this.loginId = this.dialogData.user.loginId;
    this.name = this.dialogData.user.name;
    this.role = utils.getRoleList(this.$t).find(e => e.id == this.getRoleId(this.dialogData.user));
    this.password = "";
    this.passwordConfirm = "";
    const roleId = this.getRoleId(this.dialogData.user);

    if (this.dialogData.user.possibleToLogInStoreList.length != 0) {
      if (roleId == constant.ROLE.SITE_USER.id) {
        this.shopId = this.findShop(this.dialogData.user.possibleToLogInStoreList[0]);
      } else if (roleId == constant.ROLE.ZONE_MANAGER.id) {
        const shopList = [];
        for (let id of this.dialogData.user.possibleToLogInStoreList) {
          const shop = this.findShop(id);
          if (shop) {
            shopList.push(shop);
          }
        }
        this.shopIds = shopList;
      }
    }
    this.$refs.form.validate()
  },
  methods: {
    hasLength(v) {
      return !(v == null || v == undefined || v == "");
    },
    findShop(shop) {
      return this.dialogData.shopList.find(e => e.code == shop.siteCode);
    },
    getRoleId(user) {
      if (user.isSysAdmin) return constant.ROLE.ADMIN.id;
      if (user.possibleToLogInStoreList.length > 1) return constant.ROLE.ZONE_MANAGER.id;
      return constant.ROLE.SITE_USER.id;
    },
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;
      const param = {
        userId: this.loginId,
        userName: this.name,
        role: this.role.id,
        password: this.password,
      };
      const shopList = [];
      if (this.role.id == constant.ROLE.ZONE_MANAGER.id) {
        for (var shop of this.shopIds) {
          shopList.push(shop.code);
        }
      } else if (this.role.id == constant.ROLE.SITE_USER.id) {
        shopList.push(this.shopId.code);
      }

      param.siteCodeList = shopList;
      param.mode = this.dialogData.mode;
      api.post("/user/register", param)
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMessage = "";
            this.errorList = [];
            this.handleOk();
          } else {
            this.errorMessage = response.data?.errorMessage;
            this.errorList = response.data?.errorList;
          }
        })
        .catch(() => {
          this.errorMessage = this.$t('common.message.unknownError');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>
