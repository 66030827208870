import { createRouter, createWebHistory } from 'vue-router'

import Paths from "@/router/indexPaths"
import LoginPage from "@/pages/LoginPage.vue";
import HomePage from "@/pages/HomePage.vue"
import NyuukaPage from "@/pages/NyuukaPage.vue";
import NyuukaDetailPage from "@/pages/NyuukaDetailPage.vue";
import ShukkaIdouPage from "@/pages/ShukkaPage.vue";
import ShukkaIdouDetailPage from "@/pages/ShukkaDetailPage.vue";
import ZaikoJanListPage from "@/pages/ZaikoJanListPage.vue";
import ZaikoEpcListPage from "@/pages/ZaikoEpcListPage.vue";
import ZaikoEpcEventListPage from "@/pages/ZaikoEpcEventListPage.vue";
import TanaoroshiPage from "@/pages/TanaoroshiPage.vue";
import TanaoroshiSaiPage from "@/pages/TanaoroshiSaiPage.vue";
import TanaoroshiAdjustmentHistoryPage from "@/pages/TanaoroshiAdjustmentHistoryPage.vue";
import store from '@/store/index';
import UserPage from "@/pages/UserPage.vue";
import DashboardPage from "@/pages/DashboardPage.vue";
import ZaikoMovePage from "@/pages/ZaikoMovePage.vue";
import ZaikoMoveDetailPage from "@/pages/ZaikoMoveDetailPage.vue";
import SalesPage from "@/pages/SalesPage.vue";
import AlarmEvents from "@/pages/AlarmEventsPage.vue";

function r(path: string, component: any, option?: object) {
  return {
    path: path,
    name: path,
    component: component,
    ...option
  };
}

// ルーティング設定
const routes = [
  r(Paths.HOME, HomePage),
  r(Paths.LOGIN, LoginPage),
  r(Paths.NYUUKA, NyuukaPage),
  r(Paths.NYUUKA_DETAIL, NyuukaDetailPage),
  r(Paths.SHUKKA, ShukkaIdouPage),
  r(Paths.SHUKKA_DETAIL, ShukkaIdouDetailPage),
  r(Paths.ZAIKO_JAN_LIST, ZaikoJanListPage),
  r(Paths.ZAIKO_EPC_LIST, ZaikoEpcListPage),
  r(Paths.ZAIKO_EPC_EVENT_LIST, ZaikoEpcEventListPage),
  r(Paths.TANAOROSHI, TanaoroshiPage),
  r(Paths.TANAOROSHI_SAI, TanaoroshiSaiPage),
  r(Paths.TANAOROSHI_ADJUSTMENT_HISTORY, TanaoroshiAdjustmentHistoryPage),
  r(Paths.USER, UserPage),
  r(Paths.DASHBOARD, DashboardPage),
  r(Paths.ZAIKO_MOVE, ZaikoMovePage),
  r(Paths.ZAIKO_MOVE_DETAIL, ZaikoMoveDetailPage),
  r(Paths.SALES, SalesPage),
  r(Paths.ALARM_EVENTS, AlarmEvents),
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {

  if (to.name === Paths.LOGIN || to.name === Paths.HOME) {
    next();
    return;
  }

  const availableMenuList = store.getAvailableMenuList();
  if (!availableMenuList?.find(id => id == to.name)) {
    next(Paths.HOME);
    return;
  }
  next();
});

router.beforeResolve((to: any, from: any, next: any) => {
  if(to.name === Paths.LOGIN) {
    if (store.isLoggedIn()) {
      next(Paths.HOME);
    }
    next();
  } 
  //ログインチェック
  if (store.isLoggedIn()) {
    next();
  } else {
    next(Paths.LOGIN)
  }
});

export default router;
