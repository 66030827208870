import { App } from "vue";

class _TableDefinitionManager {

    /** デフォルト定義 */
    private defaultDefinition;

    /** 会社用定義 */
    private companyDefinition;

    constructor() {

        // デフォルト定義読込
        this.defaultDefinition = require("@/plugins/setting/table/default.json");

        try {
            // 会社用定義読込
            const company = process.env.VUE_APP_COMPANY;
            this.companyDefinition = require(`@/plugins/setting/table/${company}.json`);
        } catch (e) {
            // エラーの場合は、デフォルト定義を設定
            this.companyDefinition = this.defaultDefinition;
        }
    }

    /**
     * テーブル定義取得
     * 
     * 優先順位は下記で、設定が見つからない場合はフォールバックする
     * 1. 会社用定義ファイル -> 権限ごとの定義
     * 2. 会社用定義ファイル -> デフォルトの定義
     * 3. デフォルト定義ファイル -> 権限ごとの定義
     * 4. デフォルト定義ファイル -> デフォルトの定義
     * 
     * @param tag タグ
     * @returns 
     */
    get(tag: string): string[] {

        const DEFAULT_TAG = "default";
        const role = sessionStorage.getItem("role") ?? DEFAULT_TAG;
        
        // 会社用定義ファイルに権限ごとの設定がある
        let definition = this.companyDefinition[role];
        if (definition && definition[tag]) return definition[tag];

        // 会社用定義ファイルに権限ごとの設定そのものが無い、
        // または、権限ごとの設定にタグが無い場合は、デフォルト定義ファイルを採用
        definition = this.companyDefinition[DEFAULT_TAG];
        if (definition && definition[tag]) return definition[tag];

        // デフォルト定義ファイルに権限ごとの設定がある
        definition = this.defaultDefinition[role];
        if (definition && definition[tag]) return definition[tag];

        // デフォルト定義ファイルに権限ごとの設定そのものが無い、
        // または、権限ごとの設定にタグが無い場合は、デフォルトの定義を採用
        return this.defaultDefinition[DEFAULT_TAG][tag] ?? [];
    }
}

function getTableDef() {
    const manager = new _TableDefinitionManager();
    function getTableDef(tag: string) {
        return manager.get(tag);
    }
    return getTableDef;
}

export const TableDefinitionManager = {
    install: (app: App) => {
        app.config.globalProperties.$getTableDef = getTableDef();
    }
};