<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3" class="print_col">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                :items="zoneList"
                :label="$t('tanaoroshiSai.label.targetZoneCode')"
                item-title="zoneName"
                item-value="zoneCode"
                v-model="searchCondition.targetZoneCode"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="3" class="print_col">
              <v-select
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  :items="[
                      {'key':1, 'label': $t('tanaoroshiSai.label.little')},
                      {'key':2, 'label': $t('tanaoroshiSai.label.excess')},
                      {'key':3, 'label': $t('tanaoroshiSai.label.matched')},
                      ]"
                  :label="$t('tanaoroshiSai.label.difference')"
                  item-title="label"
                  item-value="key"
                  v-model="searchCondition.filterLoss"
                  clearable
              ></v-select>
            </v-col>
            <v-col cols="3" class="print_col">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.janCode"
                :label="$t('tanaoroshiSai.label.janCode')" />
            </v-col>
            <v-col cols="3" class="print_col">
              <v-text-field density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.productNumber"
                :label="$t('tanaoroshiSai.label.productNumber')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="print_col">
              <v-switch class="no_print" v-model="showZeroInventory" color="blue" inset :label="$t('tanaoroshiSai.label.showZeroInventory')"></v-switch>
              <div class="print_only">{{ this.showZeroInventory ? $t('tanaoroshiSai.label.printShowZeroInventoryOn') : $t('tanaoroshiSai.label.printShowZeroInventoryOff') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <template v-for="header in headers" :key="header">
                      <template v-if="header == 'adjustementSuuryo'">
                        <th class="no_print"  @click.stop="tableSort(header)" :style="cursorCheck(header)">{{ $t("tanaoroshiSai.label.table." + header) }}</th>
                      </template>
                      <template v-else>
                        <th @click.stop="tableSort(header)" :style="cursorCheck(header)">{{ $t("tanaoroshiSai.label.table." + header) }}</th>
                      </template>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <template v-for="header in headers" :key="header">
                      <template v-if="header == 'adjustementSuuryo'">
                        <td class="no_print">
                          <v-text-field density="compact" variant="outlined" hide-details="auto"
                            v-model="data.adjustmentQuantity"
                            @input="fixAdjustmentQuantity(data, $event)"
                             />
                        </td>
                      </template>
                      <template v-else>
                        <td>{{ data[header] }}</td>
                      </template>
                    </template>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ sortedTable.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                v-if="canAdjustment"
                dark
                class="px-8 bg-indigo-darken-1 mr-5"
                elevation="2"
                @click="openAdjustmentDialog"
                :disabled="!hasAdjustment">{{ $t('tanaoroshiSai.action.save') }}
              </v-btn>
              <v-btn
                v-if="canAdjustment"
                dark
                class="px-8 bg-indigo-darken-1 mr-5"
                elevation="2"
                @click="openAddJanDialog">{{ $t('tanaoroshiSai.action.addJan') }}
              </v-btn>
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1 mr-5"
                elevation="2"
                @click="downloadCsv"
                :disabled="dataList.length == 0">{{ $t('tanaoroshiSai.action.outputCsv') }}
              </v-btn>

              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click="print"
                :disabled="dataList.length == 0">{{ $t('tanaoroshiSai.action.print') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <ErrorDialog v-if="dataError" :URL="previousURL"/>
        <TanaoroshiAdjustmentDialog v-if="adjustmentDialogVisible" :dialog-data="adjustmentDialog" :handle-ok="confirmAdjustmentOk"
        :handle-cancel="confirmAdjustmentCancel" />
        <TanaoroshiAddJanDialog v-if="addJanDialogVisible" :dialog-data="addJanDialog" :handle-ok="confirmAddJanOk"
        :handle-cancel="confirmAddJanCancel" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import api from "@/apis/staff";
import store from "@/store";
import utils from "@/utils";
import ErrorDialog from "@/components/dialog/ErrorDialog";
import TanaoroshiAdjustmentDialog from "@/components/dialog/TanaoroshiAdjustmentDialog";
import TanaoroshiAddJanDialog from "@/components/dialog/TanaoroshiAddJanDialog";
import constant from "@/utils/constant"

export default {
  components: {AppWrapperView, ErrorDialog, TanaoroshiAdjustmentDialog, TanaoroshiAddJanDialog},
  computed: {
    // １つ目は必ず不必要なゾーンなので、除外する
    zoneList() {
      const clonedZoneList = this.tanaoroshi?.zoneList.map(x => x) ?? [];
      clonedZoneList.shift();
      // APV在庫の場合はゾーンコードリストを表示しない
      // NOTE この値を使って条件分岐をするという意味不明なことをしているので注意
      const	nameerp = this.$t('tanaoroshiSai.label.nameerp');
      if(this.tanaoroshi?.inventoryModeLabel == nameerp){
        return [];
      }
      return clonedZoneList;
    },
    
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.sortedTable.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.sortedTable.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.sortedTable.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      // フィルター
      // ロケ
      if(this.searchCondition.targetZoneCode != null) {
        tempArray = tempArray.filter(x => x.zoneCode == this.searchCondition.targetZoneCode)
      }
      
      // 差異
      if(this.searchCondition.filterLoss != null) {
        // 過少 予定数 > 読み取り数
        if(this.searchCondition.filterLoss == 1) tempArray = tempArray.filter(x => x.planSuuryo > x.resultSuuryo)
        // 過剰 予定数 < 読み取り数
        if(this.searchCondition.filterLoss == 2) tempArray = tempArray.filter(x => x.planSuuryo < x.resultSuuryo)
        // 一致 予定数 = 読み取り数
        if(this.searchCondition.filterLoss == 3) tempArray = tempArray.filter(x => x.planSuuryo == x.resultSuuryo)
      }

      // JANコード
      if(this.searchCondition.janCode != "") {
        tempArray = tempArray.filter(x => x.janCode == this.searchCondition.janCode);
      }
      // 品番
      if(this.searchCondition.productNumber != "") {
        tempArray = tempArray.filter(x => x.productCode == this.searchCondition.productNumber);
      }
      // 0件データ非表示
      if (!this.showZeroInventory) {
        tempArray = tempArray.filter(x => x.planSuuryo != 0 || x.resultSuuryo != 0);
      }
      // 変換
      tempArray = tempArray.map((x, index) => {
        // noはなし
        x.no = index + 1;
        return x;
      })
      
      return tempArray;
    },
    canAdjustment() {
      return this.inventoryStatus == constant.INVENTORY_STATUS.SAVED;
    },
    hasAdjustment() {
      return this.dataList.some(e => e.adjustmentQuantity);
    },
    // ページ区切り後のデータ
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },
    // ヘッダー
    headers() {
      let def = this.$getTableDef("TanaoroshiSaiPage");
      if (!this.canAdjustment) {
        def = def.filter(e => e != "adjustementSuuryo");
      }
      return def;
    },

  },
  data: () => ({
    // paramより
    tanaoroshi: null,
    shopCode: null,
    dataError: false,
    searchDatetime: null,
    dataList: [],

    searchCondition: {
      targetZoneCode: null, // ロケーション
      filterLoss : null, // 差異
      janCode: "",
      productNumber: ""
    },
    inventoryStatus: null,

    currentPageNo: 1,
    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],
    //ソートの状態
    sortState: {target: "", ascent: true},

    // エラー時の遷移先
    previousURL: Paths.TANAOROSHI,
    htSerialZoneMap: null,
    // 調整ダイアログ表示フラグ
    adjustmentDialogVisible: false,
    // 調整ダイアログに渡すデータ
    adjustmentDialog: {
    },
    // 棚卸追加フラグ
    addJanDialogVisible: false,
    // 棚卸追加ダイアログに渡すデータ
    addJanDialog: {
    },
    showZeroInventory: false
  }),

  methods: {

    fixAdjustmentQuantity(data, event) {
      const val = event.target.value;
      data.adjustmentQuantity = val.replace(/[^0-9]/g, '');
    },
    cursorCheck(value){
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      if(headValue == "adjustementSuuryo") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    reload() {
      api.post("/tanaoroshi/diff/list/search", {
          tanaoroshiId: this.tanaoroshi.tanaoroshiId ?? "",
          shopCode: this.shopCode ?? "",
        }
      )
      .then((response)=>{

        const hasErr = response.data.status == '1';
        const hasData = response.data.results?.diffList?.length ?? 0 != 0;
        const inventoryStatus = response.data.results?.inventoryStatus;
        this.inventoryStatus = inventoryStatus;
        // エラーあり、またはデータが無く保存も不可の場合は、エラーとし、再検索させる
        if(hasErr || (!hasData && !this.canAdjustment)){
          this.dataError = true;
        }
        this.dataList = response.data.results?.diffList ?? [];
        this.htSerialZoneMap = response.data.results?.htSerialZoneMap;
        this.searchDatetime = this.getTimestamp();
        this.inventoryStatus = inventoryStatus;
      })
      .catch((error) => {
        // back previous page
        this.dataError = true;
      })
      this.resetCondition();
    },
    downloadCsv() {
      utils.simpleDownloadCsv(this.headers.filter(e => e != "adjustementSuuryo"),
        this.sortedTable, "tanaoroshiSai.label.table.", 'tanaoroshiSai.fileNamePrefix.csv', this.$t);
    },
    print() {
      window.print();
    },
    // 保存ダイアログ表示
    openAdjustmentDialog() {
      this.adjustmentDialog = {
        tanaoroshiId: this.tanaoroshi.tanaoroshiId,
        siteCode: this.shopCode,
        updateList: this.dataList.filter(e => 'adjustmentQuantity' in e && e['adjustmentQuantity'] != ""),
        searchDatetime: this.searchDatetime
      }
      this.adjustmentDialogVisible = true;
    },
    // 保存ダイアログキャンセル
    confirmAdjustmentCancel() {
      this.adjustmentDialogVisible = false;
    },
    // 保存ダイアログOK押下
    confirmAdjustmentOk() {
      this.reload();
      this.adjustmentDialogVisible = false;
    },
    // 棚卸情報追加ダイアログ表示
    openAddJanDialog() {
      this.addJanDialog = {
        tanaoroshiId: this.tanaoroshi.tanaoroshiId,
        siteCode: this.shopCode,
        searchDatetime: this.searchDatetime,
        htSerialZoneMap: this.htSerialZoneMap,
      }
      this.addJanDialogVisible = true;
    },
    // 棚卸情報追加ダイアログキャンセル
    confirmAddJanCancel() {
      this.addJanDialogVisible = false;
    },
    // 棚卸情報追加ダイアログOK押下
    confirmAddJanOk() {
      this.reload();
      this.addJanDialogVisible = false;
    },
    getTimestamp() {
      const date = new Date();
      return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, "0")}`
        + `${String(date.getDate()).padStart(2, "0")}${String(date.getHours()).padStart(2, "0")}`
        + `${String(date.getMinutes()).padStart(2, "0")}${String(date.getSeconds()).padStart(2, "0")}`
        + `${String(date.getMilliseconds()).padStart(3, "0")}`;
    },
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);

    //propで渡された選択中zoneCodeをformにbindしているものに移す
    if (!store.hasTanaoroshiParam()) {
      this.dataError = true;
    }
    else {
      this.tanaoroshi = store.getTanaoroshi();
      this.shopCode = store.getTanaShopCode();
      this.searchCondition.targetZoneCode = store.getTanaZoneCode();
      if(this.tanaoroshi?.zoneList.at(0).zoneCode == this.searchCondition.targetZoneCode) this.searchCondition.targetZoneCode = null;
      this.reload()
    }
    
  }
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}

.print_content {
  display: none;
  font-size: 12px;
  font-weight: bold;
  color: #555;
  text-align: left;
}

.print_head {
  padding-left: 2px;
}

.print_data_row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.print_head,
.print_data {
  width: 9%;
  padding: 4px 2px;
  border-bottom: 1px solid #ccc;
  word-break: break-all;
}
.print_only {
  display: none !important;
}
@media print {
  .v-main {
    padding: 0 0 0 0 !important;
  }

  .v-btn {
    display: none !important;
  }

  .v-data-table {
    display: none !important;
  }

  .print_col {
    margin: 0;
    padding: 0;
  }

  .print_content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .no_print {
    display: none !important;
  }
  .print_only {
    display: block !important;
  }
}
</style>
