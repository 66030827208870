<template>
  <v-dialog v-model="dialog" width="800" @click:outside="cancel" :persistent="isLoading">
    <v-card>
      <v-card-title>
        <h3><strong>{{ $t('tanaoroshiAdjustmentDialog.label.title') }}</strong></h3>
      </v-card-title>
      <v-card-text class="pa-4 pre-line grey--text text--darken-4 text-subtitle-1 px-5">
        <p class="mb-4">
          {{ $t('tanaoroshiAdjustmentDialog.message.save') }}
        </p>
        <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="staffCode"
          :label="$t('tanaoroshiAdjustmentDialog.label.staffCode')" :counter="staffCodeLength"
          :maxLength="staffCodeLength" :disabled="isLoading" :rules="[rules.checkStaffCode]" />
      </v-card-text>
      <v-row>
        <v-col cols="12">
          <v-table density="compact" class="text-no-wrap">
            <thead>
              <tr>
                <th v-for="header in headers" :key="header" @click.stop="tableSort(header)"
                  :style="cursorCheck(header)">
                  {{ $t("tanaoroshiAdjustmentDialog.label.table." + header) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in filteredDataList" :key="data">
                <template v-for="header in headers" :key="header">
                  <td>{{ data[header] }}</td>
                </template>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> {{ $t('common.action.cancel') }} </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="invalidInput">
          {{ $t('common.action.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <MessageDialog v-if="commonMsgDlgVisible" :dialog-data="data4MsgDialog" :handle-ok="closeMessageDataDialog" />
</template>

<script>
import api from "@/apis/staff";
import constant from "@/utils/constant"
import MessageDialog from "@/components/dialog/MessageDialog";

export default {
  components: { MessageDialog },
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    invalidInput() {
      if (!this.isValidStaffCode(this.staffCode)) return true;
      if ((this.staffCode.length > 0 && this.staffCode.length <= this.staffCodeLength)) return false;
      return true;
    },
    headers() {
      return this.$getTableDef("TanaoroshiAdjustmentDialog");
    },
    sortedTable() {
      let tempArray = this.dialogData.updateList.map(x => x);
      if (this.sortState.target != "") {
        return tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice();
    },
  },
  data() {
    return {
      staffCode: "",
      dialog: true,
      isLoading: false,
      errorMsg: "",
      staffCodeLength: 0,
      rules: {
        checkStaffCode: v => {
          return this.isValidStaffCode(v) || this.$t('tanaoroshiAdjustmentDialog.validation.halfAlnum');
        },
      },
      sortState: { target: "", ascent: true },
      // 汎用メッセージダイアログ
      commonMsgDlgVisible: false,
      data4MsgDialog: {
        message: "",
        errorList: [],
      },
    };
  },
  mounted() {
    this.staffCodeLength = constant.STAFF_CODE_MAX_LENGTH;
  },
  methods: {
    isValidStaffCode(v) {
      return /^[0-9A-Za-z]*$/.test(v);
    },
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;

      let updateList = [];
      for (const updateInfo of this.dialogData.updateList) {
        updateList.push({
          zoneCode: updateInfo.zoneCode,
          janCode: updateInfo.janCode,
          adjustmentQuantity: updateInfo.adjustmentQuantity,
        });
      }

      api.post("/tanaoroshi/adjustment/save", {
        tanaoroshiId: this.dialogData?.tanaoroshiId,
        siteCode: this.dialogData?.siteCode,
        staffId: this.staffCode,
        updateList: updateList,
        searchDatetime: this.dialogData?.searchDatetime,
      })
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMsg = "";
            this.handleOk();
          } else {
            this.data4MsgDialog = {};
            this.data4MsgDialog.errorList = [];
            this.data4MsgDialog.message = response.data?.errorMessage;
            this.commonMsgDlgVisible = true;
          }
        })
        .catch((error) => {
          // do nothing
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    cursorCheck() {
      return "cursor: pointer;"
    },
    tableSort(headValue) {
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      } else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },
    defaultSorter(isAscent) {
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    // 共通メッセージダイアログを閉じる
    closeMessageDataDialog() {
      this.commonMsgDlgVisible = false;
    },
  },
};
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>
